import React from 'react'
import PropTypes from 'prop-types'

function BgTeacher(props) {
  return (
    <React.Fragment>
      {
        props.className === "desktop-only" ?
          <svg width="658" height="874" viewBox="0 0 658 874" fill="none" xmlns="http://www.w3.org/2000/svg" className={props.className}>
            <g clipPath="url(#clip0)">
              <path d="M179.177 871.605C418.993 871.605 613.402 677.026 613.402 437C613.402 196.974 418.993 2.39453 179.177 2.39453C-60.6388 2.39453 -255.048 196.974 -255.048 437C-255.048 677.026 -60.6388 871.605 179.177 871.605Z" stroke="var(--cl-primary)" strokeWidth="4" strokeMiterlimit="10" strokeDasharray="12.07 15.09"/>
              <path opacity="0.2" d="M179.476 812.82C386.854 812.82 554.967 644.56 554.967 437C554.967 229.44 386.854 61.18 179.476 61.18C-27.9016 61.18 -196.015 229.44 -196.015 437C-196.015 644.56 -27.9016 812.82 179.476 812.82Z" stroke="var(--cl-secondary)" strokeWidth="4" strokeMiterlimit="10" strokeDasharray="12.07 15.09"/>
              <path opacity="0.2" d="M179.476 751.64C353.095 751.64 493.841 610.771 493.841 437C493.841 263.229 353.095 122.36 179.476 122.36C5.85733 122.36 -134.888 263.229 -134.888 437C-134.888 610.771 5.85733 751.64 179.476 751.64Z" stroke="var(--cl-primary)" strokeWidth="4" strokeMiterlimit="10" strokeDasharray="12.07 15.09"/>
              <path opacity="0.6" d="M561.082 443.603C561.877 440.633 561.877 437.505 561.081 434.535C560.285 431.564 558.721 428.856 556.546 426.681L569.29 379.123L568.981 379.041L569.026 378.871L532.509 369.087L175.318 167.74L-255.442 173.257L-260.732 192.999L-88.424 316.721L-151.25 506.509C-57.0456 562.877 20.4143 594.921 83.604 611.33L85.0077 611.706L86.9291 612.221C273.035 659.399 333.889 570.036 333.889 570.036C337.846 515.087 354.786 457.481 364.232 428.919L551.822 398.675L553.263 383.781L555.792 384.459L545.831 421.633C542.125 421.282 538.403 422.121 535.206 424.027C532.008 425.934 529.501 428.809 528.048 432.236C526.594 435.663 526.27 439.464 527.123 443.088C527.975 446.712 529.959 449.97 532.788 452.39L512.34 478.923C513.199 480.922 514.622 482.627 516.436 483.829L459.194 564.451C461.993 568.264 465.135 571.813 468.581 575.054L472.99 580.066L473.356 579.215C481.369 585.63 492.136 591.204 503.792 589.077L506.789 589.122L506.49 588.445C509.147 587.674 511.694 586.563 514.066 585.14L536.946 488.482L542.934 456.496C546.984 456.783 551.009 455.654 554.319 453.302C557.629 450.951 560.019 447.522 561.082 443.603Z" fill="var(--cl-primary)"/>
              <path d="M328.3 565.209C328.3 565.209 221.498 722.075 -143.861 503.463L-82.0639 316.878L234.615 354.622L364.064 409.913C364.064 409.913 333.698 490.106 328.3 565.209Z" fill="#FFFAE5"/>
              <path opacity="0.15" d="M-82.1134 316.865L-86.5283 333.342L120.119 474.097L343.462 442.678L350.627 426.927L127.479 446.63L-82.1134 316.865Z" fill="black"/>
              <path opacity="0.15" d="M364.33 409.995L234.881 354.704L230.3 354.16L355.467 407.63C355.467 407.63 325.144 487.824 319.745 562.927C319.745 562.927 259.916 650.815 76.5707 603.331C266.827 656.007 328.596 565.299 328.596 565.299C334.007 490.189 364.33 409.995 364.33 409.995Z" fill="#FFFAE5"/>
              <path d="M557.839 379.405L546.848 376.46L533.558 426.059L544.549 429.004L557.839 379.405Z" fill="var(--cl-primary)"/>
              <path d="M450.605 559.866C450.605 559.866 476.126 596.474 504.068 580.081L526.345 485.951L512.077 473.293L450.605 559.866Z" fill="var(--cl-primary)"/>
              <path opacity="0.7" d="M-245.349 178.917L-250.5 198.141L122.328 465.854L540.799 398.4L542.479 381.18L124.732 445.893L-245.349 178.917Z" fill="var(--cl-secondary)"/>
              <path d="M-245.349 178.917L125.655 450.409L542.479 381.18L174.155 173.554L-245.349 178.917Z" fill="#FFFAE5"/>
              <path d="M152.18 288.22C167.306 292.273 181.701 287.597 184.332 277.777C186.963 267.957 176.834 256.711 161.709 252.658C146.583 248.605 132.187 253.28 129.556 263.1C126.925 272.92 137.054 284.167 152.18 288.22Z" fill="var(--cl-primary)"/>
              <path d="M557.57 379.173L175.752 276.866L173.543 285.109L555.362 387.417L557.57 379.173Z" fill="var(--cl-primary)"/>
              <path d="M528.946 454.263C538.046 456.702 547.399 451.301 549.837 442.202C552.276 433.102 546.875 423.749 537.776 421.31C528.676 418.872 519.322 424.272 516.884 433.372C514.446 442.472 519.846 451.825 528.946 454.263Z" fill="var(--cl-primary)"/>
              <path d="M502.362 476.58C502.362 476.58 505.568 486.594 526.345 485.951L533.166 449.504L524.923 447.295L502.362 476.58Z" fill="var(--cl-primary)"/>
              <path opacity="0.2" d="M457.257 567.378L471.92 556.582L464.018 575.08L457.257 567.378Z" fill="white"/>
              <path opacity="0.2" d="M489.681 567.231L485.263 583.718L496.981 583.912L489.681 567.231Z" fill="white"/>
              <path opacity="0.15" d="M-64.6567 427.532C-64.6567 427.532 10.3861 477.079 64.2613 473.813L-64.6567 427.532Z" fill="black"/>
              <path opacity="0.15" d="M154.324 506.769C154.324 506.769 239.561 535.434 290.798 518.518L154.324 506.769Z" fill="black"/>
              <path opacity="0.15" d="M196.365 587.926C196.365 587.926 251.882 582.357 282.929 569.826L196.365 587.926Z" fill="black"/>
              <path opacity="0.15" d="M-97.7 462.843C-97.7 462.843 -19.3432 522.124 6.32278 514.265L-97.7 462.843Z" fill="black"/>
              <path d="M331.481 732.121C339.995 714.052 345.403 698.7 343.56 697.832C341.716 696.963 333.32 710.906 324.805 728.975C316.291 747.044 310.883 762.395 312.727 763.264C314.57 764.133 322.967 750.189 331.481 732.121Z" fill="var(--cl-primary)"/>
              <path d="M87.6838 646.6L70.6707 633.12L25.7438 689.823L42.757 703.303L87.6838 646.6Z" fill="var(--cl-primary)"/>
              <path opacity="0.05" d="M50.3498 707.613L74.2268 712.719L89.3552 641.975L65.4781 636.869L50.3498 707.613Z" fill="black"/>
              <path d="M51.6136 708.766L72.8455 713.263L87.8346 642.501L66.6027 638.003L51.6136 708.766Z" fill="var(--cl-secondary)"/>
              <path opacity="0.6" d="M-112.082 480.899L-144.461 549.613L311.992 764.702L344.371 695.988L-112.082 480.899Z" fill="var(--cl-primary)"/>
              <path d="M312.768 763.111C303.841 759.05 -150.223 544.916 -143.679 548.018C-119.487 509.382 -112.841 482.583 -112.841 482.583L343.605 697.676C343.605 697.676 328.301 725.845 312.768 763.111Z" fill="var(--cl-primary)" fillOpacity="0.6"/>
              <path opacity="0.05" d="M126.094 591.988L88.5193 574.409L57.9476 639.755L95.5227 657.334L126.094 591.988Z" fill="black"/>
              <path d="M122.774 593.58L88.417 577.39L57.5795 642.832L91.9368 659.022L122.774 593.58Z" fill="#FFFAE5"/>
              <path opacity="0.05" d="M116.231 590.5L93.3232 579.705L62.4858 645.147L85.3937 655.942L116.231 590.5Z" fill="black"/>
              <path d="M116.889 589.145L96.6409 579.603L64.1265 648.604L84.3751 658.145L116.889 589.145Z" fill="var(--cl-secondary)"/>
              <path d="M11.1188 135.972C10.4021 135.387 9.92418 134.561 9.77554 133.648C9.62689 132.735 9.81778 131.799 10.3121 131.018C10.3902 130.897 10.4537 130.767 10.5012 130.631C10.6322 130.244 10.6345 129.825 10.508 129.436C10.3814 129.047 10.1327 128.71 9.79879 128.474C9.46493 128.238 9.06383 128.117 8.65523 128.127C8.24664 128.138 7.85242 128.28 7.53132 128.533C7.42141 128.623 7.32234 128.726 7.23616 128.838C6.65181 129.555 5.8251 130.033 4.91232 130.182C3.99954 130.33 3.06393 130.139 2.28235 129.645C2.16132 129.567 2.03146 129.504 1.89551 129.456C1.50833 129.325 1.0892 129.323 0.700556 129.449C0.31191 129.576 -0.0254527 129.825 -0.261253 130.158C-0.497054 130.492 -0.618687 130.893 -0.608018 131.302C-0.59735 131.711 -0.454957 132.105 -0.202055 132.426C-0.112202 132.536 -0.00980947 132.635 0.103012 132.721C0.819807 133.305 1.29768 134.132 1.44632 135.045C1.59497 135.958 1.40407 136.893 0.909716 137.675C0.831662 137.796 0.768197 137.926 0.720682 138.062C0.57152 138.452 0.555181 138.88 0.674214 139.28C0.793247 139.68 1.04102 140.03 1.37905 140.275C1.71708 140.52 2.1265 140.647 2.54382 140.635C2.96114 140.624 3.36303 140.475 3.68716 140.212C3.79708 140.122 3.89614 140.02 3.98232 139.907C4.56668 139.19 5.39336 138.712 6.30614 138.563C7.21892 138.415 8.15455 138.606 8.93614 139.1C9.05717 139.178 9.18699 139.242 9.32295 139.289C9.71013 139.42 10.1293 139.422 10.5179 139.296C10.9066 139.169 11.2439 138.921 11.4797 138.587C11.7155 138.253 11.8371 137.852 11.8265 137.443C11.8158 137.035 11.6734 136.64 11.4205 136.319C11.335 136.191 11.2337 136.074 11.1188 135.972Z" fill="var(--cl-secondary)"/>
              <path opacity="0.5" d="M478.686 681.179C481.985 682.063 485.376 680.106 486.26 676.807C487.144 673.508 485.186 670.117 481.887 669.233C478.588 668.349 475.197 670.306 474.313 673.605C473.43 676.905 475.387 680.295 478.686 681.179Z" fill="var(--cl-primary)"/>
              <path opacity="0.5" d="M181.179 740.178C184.478 741.062 187.869 739.104 188.753 735.805C189.637 732.506 187.679 729.115 184.38 728.231C181.081 727.347 177.69 729.305 176.806 732.604C175.922 735.903 177.88 739.294 181.179 740.178Z" fill="#EF5350"/>
              <path opacity="0.5" d="M312.337 656.88C315.636 657.764 319.027 655.806 319.911 652.507C320.795 649.208 318.837 645.817 315.538 644.933C312.239 644.049 308.848 646.007 307.964 649.306C307.08 652.605 309.038 655.996 312.337 656.88Z" fill="var(--cl-primary)"/>
              <path opacity="0.5" d="M249.071 152.29C252.37 153.174 255.761 151.216 256.645 147.917C257.529 144.618 255.571 141.227 252.272 140.343C248.973 139.459 245.582 141.417 244.699 144.716C243.815 148.015 245.772 151.406 249.071 152.29Z" fill="var(--cl-primary)"/>
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="1085" height="874" fill="white" transform="translate(-427)"/>
              </clipPath>
            </defs>
          </svg>
          :
          <svg width="375" height="153" viewBox="0 0 375 153" fill="none" xmlns="http://www.w3.org/2000/svg" className={props.className}>
            <g clipPath="url(#clip0)">
              <path d="M175.47 359.027C274.25 359.027 354.326 278.88 354.326 180.014C354.326 81.1472 274.25 1 175.47 1C76.6897 1 -3.38721 81.1472 -3.38721 180.014C-3.38721 278.88 76.6897 359.027 175.47 359.027Z" stroke="var(--cl-primary)" strokeWidth="4" strokeMiterlimit="10" strokeDasharray="12.07 15.09"/>
              <path opacity="0.2" d="M175.593 334.814C261.012 334.814 330.258 265.507 330.258 180.014C330.258 94.5199 261.012 25.2137 175.593 25.2137C90.1744 25.2137 20.9287 94.5199 20.9287 180.014C20.9287 265.507 90.1744 334.814 175.593 334.814Z" stroke="var(--cl-secondary)" strokeWidth="4" strokeMiterlimit="10" strokeDasharray="12.07 15.09"/>
              <path opacity="0.2" d="M175.593 309.614C247.107 309.614 305.08 251.59 305.08 180.014C305.08 108.438 247.107 50.4137 175.593 50.4137C104.08 50.4137 46.1069 108.438 46.1069 180.014C46.1069 251.59 104.08 309.614 175.593 309.614Z" stroke="var(--cl-primary)" strokeWidth="4" strokeMiterlimit="10" strokeDasharray="12.07 15.09"/>
              <path opacity="0.6" d="M352.505 133.628C352.832 132.404 352.832 131.116 352.504 129.893C352.176 128.669 351.532 127.553 350.636 126.658L355.885 107.068L355.758 107.035L355.777 106.965L340.735 102.935L193.609 20L16.1789 22.2724L14 30.4042L84.9735 81.3652L59.0956 159.539C97.8981 182.757 129.804 195.956 155.832 202.714L156.41 202.869L157.201 203.081C233.858 222.514 258.924 185.705 258.924 185.705C260.554 163.072 267.531 139.344 271.422 127.579L348.69 115.122L349.284 108.987L350.326 109.266L346.223 124.578C344.696 124.434 343.163 124.779 341.846 125.564C340.529 126.35 339.496 127.534 338.898 128.946C338.299 130.357 338.166 131.923 338.517 133.416C338.868 134.908 339.685 136.25 340.85 137.247L332.428 148.176C332.782 148.999 333.368 149.702 334.115 150.197L310.537 183.405C311.69 184.976 312.984 186.437 314.404 187.772L316.22 189.837L316.37 189.486C319.671 192.129 324.106 194.424 328.907 193.548L330.141 193.567L330.018 193.288C331.113 192.97 332.162 192.513 333.139 191.927L342.563 152.113L345.029 138.938C346.698 139.057 348.356 138.592 349.719 137.623C351.082 136.654 352.067 135.242 352.505 133.628Z" fill="var(--cl-primary)"/>
              <path d="M256.621 183.717C256.621 183.717 212.63 248.33 62.1387 158.284L87.5928 81.4298L218.033 96.9766L271.353 119.751C271.353 119.751 258.845 152.782 256.621 183.717Z" fill="#FFFAE5"/>
              <path opacity="0.15" d="M87.5729 81.4245L85.7544 88.2114L170.872 146.188L262.867 133.247L265.818 126.759L173.904 134.875L87.5729 81.4245Z" fill="black"/>
              <path opacity="0.15" d="M271.462 119.785L218.142 97.0103L216.255 96.7863L267.812 118.811C267.812 118.811 255.322 151.842 253.098 182.777C253.098 182.777 228.454 218.978 152.935 199.42C231.301 221.117 256.744 183.754 256.744 183.754C258.972 152.817 271.462 119.785 271.462 119.785Z" fill="#FFFAE5"/>
              <path d="M351.169 107.185L346.642 105.972L341.167 126.401L345.695 127.614L351.169 107.185Z" fill="var(--cl-primary)"/>
              <path d="M306.999 181.516C306.999 181.516 317.511 196.595 329.02 189.843L338.196 151.071L332.319 145.857L306.999 181.516Z" fill="var(--cl-primary)"/>
              <path opacity="0.7" d="M20.336 24.6038L18.2144 32.5222L171.782 142.793L344.15 115.009L344.842 107.916L172.772 134.571L20.336 24.6038Z" fill="var(--cl-secondary)"/>
              <path d="M20.3359 24.6038L173.152 136.431L344.842 107.916L193.129 22.3948L20.3359 24.6038Z" fill="#FFFAE5"/>
              <path d="M184.078 69.6256C190.308 71.295 196.238 69.369 197.321 65.3241C198.405 61.2793 194.233 56.6471 188.003 54.9777C181.773 53.3082 175.843 55.2339 174.759 59.2787C173.675 63.3235 177.848 67.9562 184.078 69.6256Z" fill="var(--cl-primary)"/>
              <path d="M351.058 107.089L193.787 64.9489L192.877 68.3442L350.148 110.485L351.058 107.089Z" fill="var(--cl-primary)"/>
              <path d="M339.268 138.019C343.016 139.023 346.868 136.799 347.873 133.051C348.877 129.302 346.653 125.45 342.905 124.445C339.156 123.441 335.303 125.665 334.299 129.414C333.295 133.162 335.519 137.014 339.268 138.019Z" fill="var(--cl-primary)"/>
              <path d="M328.317 147.211C328.317 147.211 329.638 151.336 338.196 151.071L341.006 136.058L337.61 135.149L328.317 147.211Z" fill="var(--cl-primary)"/>
              <path opacity="0.15" d="M94.7627 127.008C94.7627 127.008 125.673 147.417 147.864 146.071L94.7627 127.008Z" fill="black"/>
              <path opacity="0.15" d="M81.1523 141.553C81.1523 141.553 113.428 165.971 123.999 162.733L81.1523 141.553Z" fill="black"/>
              <path d="M20.5855 134.095C24.0924 126.653 26.0308 120.193 24.9154 119.667C23.7996 119.142 20.0521 124.749 16.5452 132.191C13.0383 139.634 11.0995 146.093 12.2153 146.619C13.3307 147.144 17.0782 141.537 20.5855 134.095Z" fill="var(--cl-primary)"/>
              <path opacity="0.6" d="M25.2285 118.99L11.8916 147.293L199.904 235.888L213.241 207.585L25.2285 118.99Z" fill="var(--cl-primary)"/>
              <path d="M200.224 235.233C196.547 233.56 9.51845 145.359 12.2139 146.636C22.1786 130.722 24.9161 119.684 24.9161 119.684L212.926 208.28C212.926 208.28 206.622 219.883 200.224 235.233Z" fill="var(--cl-primary)" fillOpacity="0.6"/>
              <path opacity="0.5" d="M51.0592 42.3491L49.8289 42.0196L47.9609 48.9906L49.1913 49.3205L51.0592 42.3491Z" fill="#EF5350"/>
              <path opacity="0.5" d="M52.8313 47.219L53.1608 45.9886L46.1898 44.1207L45.8599 45.351L52.8313 47.219Z" fill="#EF5350"/>
              <path d="M14.0684 117.905C13.7735 117.665 13.5766 117.324 13.5152 116.948C13.4538 116.572 13.5325 116.187 13.7364 115.865C13.7685 115.815 13.7945 115.762 13.8142 115.706C13.8682 115.546 13.869 115.374 13.8167 115.213C13.7648 115.053 13.6622 114.914 13.5247 114.817C13.3871 114.72 13.2219 114.67 13.0539 114.674C12.8854 114.679 12.7231 114.738 12.5909 114.841C12.5456 114.878 12.5048 114.921 12.4694 114.967C12.2284 115.262 11.8882 115.459 11.5121 115.521C11.1361 115.582 10.7505 115.503 10.4288 115.299C10.379 115.267 10.3254 115.241 10.2694 115.222C10.11 115.168 9.93744 115.167 9.77721 115.219C9.61698 115.271 9.47817 115.374 9.38096 115.511C9.28375 115.648 9.23391 115.813 9.23803 115.982C9.24256 116.15 9.30105 116.313 9.40526 116.445C9.44233 116.49 9.48476 116.531 9.53089 116.566C9.82622 116.807 10.0231 117.148 10.0845 117.524C10.1454 117.9 10.0672 118.285 9.86329 118.607C9.83117 118.657 9.80522 118.71 9.78545 118.766C9.72407 118.927 9.71748 119.103 9.7665 119.268C9.81551 119.433 9.91725 119.577 10.0565 119.678C10.1961 119.779 10.3646 119.831 10.5363 119.826C10.7085 119.822 10.8737 119.76 11.0076 119.652C11.0529 119.615 11.0936 119.573 11.1291 119.526C11.3696 119.231 11.7103 119.034 12.0863 118.973C12.462 118.912 12.8475 118.99 13.1696 119.194C13.2194 119.226 13.2726 119.252 13.3286 119.272C13.4884 119.326 13.661 119.327 13.8208 119.275C13.9811 119.222 14.1199 119.12 14.2171 118.983C14.3143 118.845 14.3645 118.68 14.36 118.511C14.3555 118.343 14.297 118.181 14.1928 118.048C14.1578 117.996 14.1157 117.947 14.0684 117.905Z" fill="var(--cl-secondary)"/>
              <path d="M106.247 56.0205C105.952 55.7795 105.755 55.4393 105.694 55.0632C105.633 54.6871 105.711 54.3016 105.915 53.9799C105.947 53.9301 105.973 53.8765 105.993 53.8205C106.047 53.6611 106.048 53.4885 105.995 53.3283C105.943 53.1681 105.841 53.0293 105.703 52.932C105.566 52.8348 105.401 52.785 105.232 52.7891C105.064 52.7936 104.901 52.8521 104.769 52.9563C104.724 52.9934 104.683 53.0358 104.648 53.082C104.407 53.3773 104.067 53.5742 103.69 53.6356C103.315 53.6965 102.929 53.6179 102.607 53.4144C102.557 53.3823 102.504 53.3563 102.448 53.3365C102.288 53.2826 102.116 53.2817 101.956 53.3336C101.796 53.386 101.657 53.4885 101.56 53.6257C101.463 53.7633 101.412 53.9284 101.417 54.0969C101.421 54.2654 101.48 54.4276 101.584 54.5599C101.621 54.6052 101.663 54.646 101.71 54.6814C102.005 54.9219 102.202 55.2626 102.263 55.6386C102.324 56.0147 102.246 56.3998 102.042 56.7219C102.01 56.7718 101.984 56.8253 101.964 56.8813C101.903 57.042 101.896 57.2183 101.945 57.383C101.994 57.5478 102.096 57.6919 102.235 57.7929C102.374 57.8938 102.543 57.9461 102.715 57.9411C102.887 57.9366 103.052 57.8752 103.186 57.7669C103.231 57.7298 103.272 57.6878 103.307 57.6413C103.548 57.346 103.889 57.1491 104.265 57.0877C104.641 57.0267 105.026 57.1054 105.348 57.3089C105.398 57.341 105.451 57.3674 105.507 57.3867C105.667 57.4407 105.839 57.4415 106 57.3896C106.16 57.3373 106.299 57.2352 106.396 57.0976C106.493 56.96 106.543 56.7948 106.538 56.6264C106.534 56.4583 106.475 56.2956 106.372 56.1634C106.336 56.1107 106.294 56.0625 106.247 56.0205Z" fill="var(--cl-secondary)"/>
              <path opacity="0.5" d="M204.259 62.7418C205.618 63.106 207.015 62.2995 207.379 60.9406C207.743 59.5818 206.937 58.185 205.578 57.8209C204.219 57.4568 202.822 58.2633 202.459 59.6221C202.094 60.981 202.901 62.3777 204.259 62.7418Z" fill="var(--cl-primary)"/>
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="437.849" height="360" fill="white" transform="translate(-46)"/>
              </clipPath>
            </defs>
          </svg>

      }
    </React.Fragment>
  )
}

BgTeacher.propTypes = {
  className: PropTypes.string
}

export default BgTeacher

